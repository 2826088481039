<template>
    <div class="dr_detail_box">
        <div class="dr_detail_info_box">
            <div class="dr_detail_img">
              <img :src="returnItemImage()" alt="">
            </div>
            <div class="dr_detail_info">
                <div class="detail_info">
                  <div class="detail_title">{{$t('request_number')}}</div><br>
                  <div class="detail_text">{{detectionData.l_uuid}}</div>
                </div>
                <div class="detail_info">
                  <div class="detail_title">{{$t('detect_img_create_date')}}</div><br>
                  <div class="detail_text">{{returnDateFormat(returnItem().created, 'YYYY.MM.DD')}}</div>
                </div>
                <div class="detail_info">
                  <div class="detail_title">{{$t('detect_img_place')}}</div><br>
                  <div class="detail_text font_kr">{{detectionData.l_place}}</div>
                </div>
                <div class="detail_info">
                  <div class="detail_title">{{$t('email')}}</div><br>
                  <div class="detail_text">{{detectionData.l_email}}</div>
                </div>
                <div class="detail_info">
                  <div class="detail_title">{{$t('law_page_title')}}</div><br>
                  <div class="detail_text">{{detectionData.dr_title}}</div>
                </div>
            </div>
            <div class="dr_detail_info">
                <div class="detail_info">
                  <div class="detail_title">{{$t('detect_img_filming_date')}}</div><br>
                  <div class="detail_text">{{returnDateFormat(detectionData.l_date, 'YYYY.MM.DD')}}</div>
                </div>
                <div class="detail_info">
                  <div class="detail_title">{{$t('detect_img_detect_date')}}</div><br>
                  <div class="detail_text">{{returnDateFormat(detectionData.detect_date, 'YYYY.MM.DD')}}</div>
                </div>
                <div class="detail_info">
                  <div class="detail_title">{{$t('compensation')}}</div><br>
                  <div class="detail_text">{{numFormat(detectionData.l_price)}}</div>
                </div>
                <div class="detail_info">
                  <div class="detail_title">{{$t('de_phone')}}</div><br>
                  <div class="detail_text">{{detectionData.l_hp}}</div>
                </div>
            </div>
            <div class="dr_detail_info info_url">
                <div class="detail_info">
                  <div class="detail_title">{{$t('law_page_url')}}</div><br>
                  <div class="detail_text">{{detectionData.dr_page}}</div>
                </div>
                <div class="detail_info">
                  <div class="detail_title">{{$t('law_image_url')}}</div><br>
                  <div class="detail_text">{{detectionData.dr_image}}</div>
                </div>
                <div class="detail_info bottom_info">
                  <div class="detail_title">{{$t('law_info_url')}}</div><br>
                  <div class="detail_text url" v-if="!isEmpty(detectionData.l_url_1)">{{detectionData.l_url_1}}</div>
                  <div class="detail_text url" v-if="!isEmpty(detectionData.l_url_2)">{{detectionData.l_url_2}}</div>
                  <div class="detail_text url" v-if="!isEmpty(detectionData.l_url_3)">{{detectionData.l_url_3}}</div>
                </div>
            </div>
        </div>
        <div class="request_progress_wrap">
            <div class="request_progress_title">{{$t('my_law_history_title')}}</div>
            <table class="rp_table"  width="100%"  border="0" cellspacing="0" cellpadding="0">
                <colgroup>
                  <col width="140px" />
                  <col width="260px" />
                  <col width="800px" />
                </colgroup>
                <tr class="rp_header">
                    <td colspan="1">{{ $t('progress_date') }}</td>
                    <td colspan="1">{{ $t('status') }}</td>
                    <td colspan="1">{{ $t('explanation') }}</td>
                </tr>
                <tr class="rp_list" v-for="log in returnLog()" :key="`lawLog${log.ll_idx}`" v-if="returnDetectStatus(log.l_status) != false">
                    <td colspan="1">{{returnDateFormat(log.created_at, 'YYYY.MM.DD')}}</td>
                    <!--의뢰 취소 시 빨간 텍스트 클래스는 red_text 입니다.-->
                    <td colspan="1" :class="returnDetectStatus(log.l_status).class">{{$t(returnDetectStatus(log.l_status).name)}}</td>
                    <td colspan="1" class="text">{{$t(returnDetectStatus(log.l_status).explanation)}}</td>
                </tr>
            </table>
        </div>
        <div class="rp_btn_wrap">
          <button class="rp_btn" @click="$router.back()">
            <span>{{ $t('list_view') }}</span>
          </button>
        </div>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import util from "@/mixins/util";
import {detectionStatus} from "@/service/detectionService";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "DetectionDetailRequestLayout",
    mixins: [imageOption, dateMixins, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        detectionData: {default: () => {}},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        returnItemImage() {
            if(util.isEmpty(this.returnItem()) || util.isEmpty(this.returnItem().ItemFile) || util.isEmpty(this.returnItem().ItemFile.Item)) {
                return "";
            }
            //return `${this.returnItem().ItemFile.Item[0].org_url}?${this.setImageOptions(580, null, null)}`
            return this.setFitWidthImg(this.returnItem().ItemFile.Item, 580);
        },
        returnItem() {
            if(util.isEmpty(this.detectionData) || util.isEmpty(this.detectionData.Item)) {
                return {}
            }
            return this.detectionData.Item;
        },
        numFormat(num) {
            return util.isEmpty(num) || isNaN(num) ? '-' : util.Number.numFormat(num);
        },
        isEmpty(data) {
            return util.isEmpty(data);
        },
        returnLog() {
            return util.isEmpty(this.detectionData.Log) ? [] : this.detectionData.Log;
        },
        returnDetectStatus(status) {
            let index = detectionStatus.findIndex(ds => ds.value === status);
            if(index > -1) {
                return detectionStatus[index];
            }
            return false;
        },
        movePage(url) {
            this.$router.push(url)
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
